import React, { useState } from 'react';

import useForm from 'react-hook-form';

// @ts-ignore
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Image from '../components/image';
import { useComponentMounted } from '../hooks/componentMounted';

const Contact = () => {
  const { register, handleSubmit, errors } = useForm();
  const [formSent, setFormSent] = useState(false);
  const [name, setName] = useState('');

  const { componentMounted } = useComponentMounted();

  const onSubmit = (data: any) => {
    setName(data.name);
    fetch('https://mailer.luka-korolija.com/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(res => {
      console.log('sent');
      // eslint-disable-next-line no-unused-expressions
      res.status === 200 ? setFormSent(true) : '';
    });
  };

  return (
    <Layout>
      <SEO title="Contact" />
      <div className="contact-content">
        <div className="row">
          <div className="col-md contact-form">
            <Fade duration={500} when={componentMounted} top>
              <h2 className="mb-3">Contact Form</h2>
            </Fade>
            {formSent ? (
              <Fade duration={400} bottom>
                <div className="form-complete">
                  <h3>Hey {name}! </h3>
                  <hr />
                  <p>
                    Thanks for reaching out to me! I will try to respond in the
                    next 24 hours.
                  </p>
                </div>
              </Fade>
            ) : (
              <Fade duration={600} when={componentMounted} left>
                <p>
                  If you have any questions, feel free to contact me using the
                  form below, and I will kindly reply.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md pr-md-1">
                        <input
                          type="text"
                          name="name"
                          placeholder="Your Name"
                          className="form-control"
                          ref={register({
                            required: 'Please enter your name.',
                            max: 50,
                            pattern: {
                              value: /^[A-Za-z]+$/i,
                              message: 'Name can only contain letters.',
                            },
                          })}
                        />
                        {errors.name ? (
                          <Fade duration={1000} left>
                            <small className="text-danger">
                              {errors.name && errors.name.message}
                            </small>
                          </Fade>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="col-md pl-md-1">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your Email"
                          className="form-control"
                          ref={register({
                            required: 'Please enter your email.',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'Invalid email address.',
                            },
                          })}
                        />
                        {errors.email ? (
                          <Fade duration={1000} left>
                            <small className="text-danger">
                              {errors.email && errors.email.message}
                            </small>
                          </Fade>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      className="form-control"
                      ref={register({ required: 'Please provide a subject.' })}
                    />
                    {errors.subject ? (
                      <Fade duration={1000} left>
                        <small className="text-danger">
                          {errors.subject && errors.subject.message}
                        </small>
                      </Fade>
                    ) : (
                      ''
                    )}
                    <textarea
                      name="message"
                      className="form-control"
                      placeholder="Message"
                      ref={register({ required: 'Please provide a message.' })}
                      cols={30}
                      rows={10}
                    />
                    {errors.message ? (
                      <Fade duration={1000} left>
                        <small className="text-danger">
                          {errors.message && errors.message.message}
                        </small>
                      </Fade>
                    ) : (
                      ''
                    )}
                    <input
                      type="checkbox"
                      name="hidden"
                      value="bot_check"
                      ref={register}
                      hidden
                    />
                    <input
                      type="submit"
                      value="Send"
                      className="submit-button mt-2 float-right"
                    />
                  </div>
                </form>
              </Fade>
            )}
          </div>
          <div className="col-md">
            <Fade delay={300} when={componentMounted} duration={600} bottom>
              <h2>Social</h2>
              <div className="profile-pic mx-auto">
                <Image filename="profile-pic.jpg" alt="Picture of me" />
              </div>
              <h4 className="text-center location mt-2">
                <FontAwesomeIcon icon={faMapMarkedAlt} /> Zrenjanin, Serbia
              </h4>
              <hr />
              <div className="social mt-4">
                <Fade delay={400} when={componentMounted} duration={500} right>
                  <a
                    href="https://github.com/lukkoro"
                    className="github mr-2 mb-3"
                  >
                    <div className="social-link-wrapper">
                      <h4 className="icon d-inline">
                        <FontAwesomeIcon icon={faGithub} />
                      </h4>
                      <h4 className="link pl-2 d-inline">github.com/lukkoro</h4>
                    </div>
                  </a>
                </Fade>
                <Fade delay={450} when={componentMounted} duration={500} right>
                  <a
                    href="https://www.linkedin.com/in/luka-korolija/"
                    className="linkedin mb-3"
                  >
                    <div className="social-link-wrapper">
                      <h4 className="icon d-inline">
                        <FontAwesomeIcon icon={faLinkedin} />
                      </h4>
                      <h4 className="link pl-2 d-inline">
                        linkedin.com/in/luka-korolija
                      </h4>
                    </div>
                  </a>
                </Fade>
                <Fade delay={500} when={componentMounted} duration={500} right>
                  <a
                    href="mailto:hey@luka-korolija.com"
                    className="envelope mb-4"
                  >
                    <div className="social-link-wrapper">
                      <h4 className="icon d-inline">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </h4>
                      <h4 className="link pl-2 d-inline">
                        hey@luka-korolija.com
                      </h4>
                    </div>
                  </a>
                </Fade>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
