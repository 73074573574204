import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// Note: You can change "images" to whatever you'd like.

interface IProps {
  filename: string;
  alt: string;
}

const Image = ({ filename, alt }: IProps) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(
        (n: {
          node: { relativePath: { includes: (arg0: string) => void } };
        }) => {
          return n.node.relativePath.includes(filename);
        }
      );
      if (!image) {
        return null;
      }

      // const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return <Img alt={alt} fluid={image.node.childImageSharp.fluid} />;
    }}
  />
);

export default Image;
